















































@import '~@/styles/import';
.button-big {
  @apply h-64 w-full flex items-center justify-center px-30 py-22 bg-primary z-50 text-white rounded-32 font-semibold;
  &:disabled {
    @apply bg-grayBlue cursor-not-allowed;
  }
}
